import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-list.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 text-transform: capitalize;
 text-align:center;
`
const BrandLogo = styled.div`
 position:relative;
 margin-left:auto;
 margin-right:auto;
 max-width:200px;
 margin-top:10px;
`
const PageTitleWrapper = styled.div`
text-align:center;
font-size:16px;
img{ margin-top:10px;}
p{ margin-bottom:0;}
`
const SectionTitle = styled.h2`
 margin-bottom:0;
`
const BrandWrapper = styled.div`
  &+&{
    margin-top:100px;
    ${BreakpointDown.md`
     margin-top:40px;
    `}
  }
`
// blog Card 

const BrandRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 ${BreakpointDown.lg`
  margin-left:-15px;
  margin-right:-15px;
 `}

`
const BrandColumn = styled.div`
 flex:0 0 100%;
 width:100%;
 padding-left:20px;
 padding-right:20px;
 margin-top:40px;
 ${BreakpointDown.lg`
   padding-left:15px;
 padding-right:15px;
 `}
 ${BreakpointDown.md`
  margin-top:30px;
 `}
`

const Card = styled.div`
  padding: 70px 62px;
  background:#fff;
  display:flex;
  height:100%;
  align-items:center;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 15px;
  }
  ${BreakpointDown.md`
   text-align:center;
   flex-wrap:wrap; 
 `}
  .btn{
    margin-top:0px;
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
`
const CardImg = styled.div`
  position: relative;
  max-width:200px;
  width:100%;
  margin-top:26px;
  ${BreakpointDown.md`
     max-width:200px;
     margin-top:0px;
     margin-left:auto;
     margin-right:auto; 
  `}
  .gatsby-image-wrapper{ 
    width:100%;
  }
`
const BrnadDesciption = styled.div`
  display:flex;
  flex-direction:column;
  padding:30px 30px 30px 130px;
${BreakpointDown.lg`
   padding:15px;
`}
${BreakpointDown.md`
   padding:0;
   margin-top:15px;
`}
p{ font-size:16px;}
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
function BrandListPage() {
  return (
    <Layout pageName="brand-list">
      <SEO
        title="BELDEN: Ethernet Cabling Solutions for Businesses"
        description="WYN Technologies has BELDEN cabling and networking solutions for manufacturers, energy producers, hospitality and enterprise clients, and various governments."/>
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpt="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">belden</Link>
          </BreadCrumb>
          <PageTitle>BELDEN: Ethernet Cabling Solutions</PageTitle>
          <BrandLogo>
            <StaticImage
              src="../images/brand-1.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Letast Article"
              placeholder="blurred"
            />
          </BrandLogo>
          <p><strong>BELDEN</strong> has been a leader in the design and manufacturing of insulated wire, cable, and related products for well over 100 years. What started out as a cable company founded in Chicago has transformed into a comprehensive signal transmission solutions provider, with an impressive portfolio including cable, connectivity, and networking products. If you need a networking cable solution, BELDEN has it!</p>
        </div>
      </SectionBgAfter>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Why BELDEN?</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>Our world has become more connected than ever before, and BELDEN has been a part of that journey since 1902. The connections and networks which have developed and which continue to grow are increasingly vital to our lives, as well as to our long-term goals. BELDEN’s singular mission is to connect and protect organizations around the world with the industry’s most complete suite of end-to-end networking solutions.</p>
                <p>BELDEN is proud to work alongside manufacturers, energy producers, hospitality and enterprise clients, mass transit operators, governments, and more to help them make both internal and external connections in increasingly reliable and secure ways. For more than a century, BELDEN has helped organizations to create better efficiencies, protect their mission-critical infrastructures, and make their operations smoother and more reliable. Their expert team is committed to continue meeting the demands which shape our increasingly connected world. It’s not by accident that they chose this tagline for their brand – BELDEN: Sending All The Right Signals.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

      {/* <Section pt="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" pb="100px" xpb="60px">
        <div className="container">
          <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle className="h1">Products</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
         <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle to="/brand-detail">IP Phone Systems</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
        </div> 
      </Section>*/}
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px" bgColor="rgb(248, 248, 248)">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">A Few Examples of BELDEN Products We Install & Configure at WYN Technologies</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <h3>ETHERNET CABLE</h3>
                <p>BELDEN’s comprehensive range of Ethernet cables offers solutions for practically any type of application. Many options featured their patented Bonded-Pair design for peak signal integrity, and their DataTuff® Industrial Ethernet cables are designed to withstand the harshest environmental conditions.</p>
                 <ul>
                  <li>
                    <strong>Cat 3 Cable</strong>
                    <p>DataTwist 3 products come in a variety of pair counts, ranging for 2 to 400 in riser and plenum.</p>
                  </li>
                  <li>
                    <strong>Cat 5e Cable</strong>
                    <p>Ensure maximum performance and stability in renovations, including both price-sensitive and even legacy IP applications.</p>
                  </li>
                  <li>
                    <strong>Cat 6 Cable</strong>
                    <p>Several Category 6 performance levels are available, and every level exceeds TIA-568-C.2 performance standards.</p>
                  </li>
                  <li>
                    <strong>Cat 6A Cable</strong>
                    <p>Options include small diameter, snake, industrial, non-bonded, patented Bonded-Pair, and more!</p>
                  </li>
                  <li>
                    <strong>Cat 7 Cable</strong>
                    <p>These ultra-reliable cables pair well with the most bandwidth-intensive applications.</p>
                  </li>
                <li>
                    <strong>Cat 7A Cable</strong>
                    <p>Bandwidth, and accompanying bandwidth needs, continue to grow. These reliable, future-ready Category 7A cables can help.</p>
                  </li>
                <li>
                    <strong>Industrial Ethernet Cable</strong>
                    <p>If you need something designed to handle the rigors of industrial applications, you can rely on BELDEN’s DataTuff Industrial Ethernet Cables.</p>
                  </li>
                </ul>
                </MarkdownContent>
                <MarkdownContent>
                <h3>CONNECTORS</h3>
                <p>BELDEN’s high-performance connector products are a trusted partner for industrial automation, infrastructure connectivity, and cybersecurity solutions.</p>
                 <ul>
                  <li>
                    <strong>Fiber Connectors</strong>
                    <p>BELDEN’s field-terminated fiber connectors make your work go faster, easier and better. These fiber connectors don't require intensive training or tooling expenses. Choose from three fiber field-term techniques: FX Fusion Splice-on connectors, FX Brilliance mechanical splice connectors, and Mass Fusion Pigtails. Each is available in all standard connector types.</p>
                  </li>
                  <li>
                    <strong>Fiber Adapters</strong>
                    <p>These standalone Fiber Adapter modules can be used in a variety of applications. Connector types include LC Duplex, SC Simplex, ST and MPO in multimode and singlemode for up to 12 fibers. BELDEN offers KeyConnect and MDVO Fiber Adapter modules, as well.</p>
                  </li>
                  <li>
                    <strong>Copper RJ45 Connectors</strong>
                    <p>These connectors come in various styles and footprints, including RJ45 jacks, plugs and couplers for both commercial and industrial installations. The portfolio covers Category 5E, Category 6, and Category 6A for both shielded and unshielded needs. Reliability, high performance, and ease of termination is built into every component. You can also combine with Belden Ethernet cables for end-to-end system performance.</p>
                  </li>
                  <li>
                    <strong>Coaxial Connectors</strong>
                    <p>Designed specifically for broadcast and commercial AV coax cable usage, BELDEN connectors maximize signal integrity and ensure quality, performance, and durability. They exceed SMPTE return loss standards, and provide excellent headroom and true 75 Ohm performance whenever broadcasters may need to push the limits. There are also specific products for commercial audio distribution, video display, automation, and lighting control.</p>
                  </li>
                  <li>
                    <strong>AV Multimedia Modules</strong>
                    <p>BELDEN offers a variety of copper and fiber AV Multimedia Modules to serve as interconnects between AV sources and monitors, projectors, and audio components. They’re available in KeyConnect and MDVO-style footprints, too. These modules can be specified with several high-quality couplers, including 3.5 mm audio jack, RCA, coax, S-Video, HDMI, and USB.</p>
                  </li>
                <li>
                    <strong>Industrial Connectors</strong>
                    <p>BELDEN's industrial connector product portfolio is designed to achieve secure data transmission in a wide range of applications, even under the most challenging of conditions. Enable complete signal transmission from a single source with their portfolio of diverse products.</p>
                  </li>
                </ul>
                </MarkdownContent>
                 <MarkdownContent>
                <h3>PATCH CORDS, CORDSETS, & ASSEMBLIES</h3>
                <p>Does your network require some good patch cords, cordsets, or related assemblies? BELDEN has them, and they can accommodate practically any type of application.</p>
                 <ul>
                  <li>
                    <strong>Broadcast & AV Assemblies</strong>
                    <p>To support increased bandwidth demands in broadcast and entertainment environments, BELDEN offers a wide variety of pre-terminated broadcast assemblies using SMPTE hybrid, tactical fiber, coax, triax, and audio cables. All assemblies are manufactured to superior quality standards, and each undergoes rigorous testing – including a test report that verifies reliable signal transmission – before ever being shipped out.</p>
                  </li>
                  <li>
                    <strong>Copper Pre-Term Assemblies</strong>
                    <p>Pre-terminated copper assemblies include trunk assemblies, pigtails, and commercial-grade or industrial copper patch cords. Offering superior quality and performance, the robust design of these assemblies means they’re able to withstand all the rigors of daily use. Copper pre-term assemblies are available in Category 5e, 6, and 6A shielded and unshielded options. Unique versions also include traceable patch cords, and trunk assemblies in various bundle sizes.</p>
                  </li>
                  <li>
                    <strong>Fiber Pre-Term Assemblies</strong>
                    <p>Fiber patch cords, hydra patch cords, mass fusion pigtails, MPO trunk assemblies, and multi-fiber cable assemblies are all available from BELDEN. They work well in daily usage situations, including both off-the-shelf standard configurations as well as custom-tailored installations. Take advantage of architectural design flexibility with these low-loss OM4 0.2 dB MPO and 0.15 dB LC connectors.</p>
                  </li>
                  <li>
                    <strong>Industrial Cordsets</strong>
                    <p>BELDEN offers a broad range of single-ended and double-ended cordsets for faster, easier installation and maintenance. With options rated for protection from water, dust, contact, vibration, heat, and flame, these cordsets deliver optimum signal protection even in extremely challenging environments.</p>
                  </li>
                  </ul>
                </MarkdownContent>
             </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
      
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Got Questions? Ready to Get Started? WYN Technologies Can Help!</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                 <p>Tell us about your goals & needs for ethernet cabling and related products. Not only can <strong>WYN Technologies</strong> deliver and install the right BELDEN product solution for you, but we can do much more than that. <strong>WYN</strong> is here to provide Whatever You Need to solve your business technology problems! Call us today at <a href="tel:3368990555">(336) 899-0555</a>.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

    </Layout>
  )
}
export default BrandListPage
